@import 'themes';

@import '~@nebular/theme/styles/globals';

@include nb-install() {
  @include nb-theme-global();
};
/* You can add global styles to this file, and also import other style files */
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
	max-width: 150px !important;
}
nb-window {
	max-width: 100vw;
}
nb-card-body {
	&.overflow-visible {
		overflow: visible;
	}
}
.mobile  {
	nb-card-body {
		padding: 1rem 0 0;

		&.minpadding {
			padding: 1rem;
		}
	}
	nb-window {
		max-width: 100vw;
		max-height: 90vh;
		overflow: hidden;
		left: 0vw;

		&.phototheque {
			max-height: 100vh;
			height: 100vh;
			width: 100vw;
		}

		> nb-card {
			max-height: 90vh;
			> nb-card-header {
				padding: 0 20px;
			}

			> nb-card-body {
				padding-top: 0;
			}
		}

		

		.title {
			margin-right: 0;
			max-width: 75%;
			text-overflow: unset;
			white-space:initial;
		}
		
	}
	
	nb-card {
		&.low-padding {
			margin-bottom: 5px;

			> nb-card-header {
				padding: 5px 10px;
			}

			> nb-card-body {
				padding-top: 0;

				nb-list {
					> nb-list-item {
						padding: 5px 10px;
					}
				}
			}
		}
	}

	nb-accordion {
		&.low-padding {
			margin-bottom: 5px;

			nb-accordion-item-header {
				padding: 5px 10px;
			}

			nb-accordion-item-body .item-body {
				padding: 0;

				nb-list {
					> nb-list-item {
						padding: 5px 10px;
					}
				}
			}
			
			// > nb-card-body {
			// 	padding-top: 0;

			// 	nb-list {
			// 		> nb-list-item {
			// 			padding: 5px 10px;
			// 		}
			// 	}
			// }
		}
	}

	.cdk-overlay-pane {
		nb-card-body {
			padding-top: 0;

			nb-list {
				> nb-list-item {
					padding: 5px 10px;

					.container {
						padding: 0;
					}
				}
			}
		}
	}
}

table.dataTable tbody tr.selected>* {
	box-shadow: inset 0 0 0 9999px rgba(166, 203, 250, 0.9) !important;
	color: black !important;
}